import React, { ReactNode, MouseEventHandler, forwardRef, CSSProperties } from 'react';
import styles from './ButtonIcon.module.scss';

interface ButtonIconProps {
    children: ReactNode;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    className?: string;
    style?: CSSProperties;
    ref?: React.Ref<HTMLButtonElement>;
}

const ButtonIcon = forwardRef<HTMLButtonElement, ButtonIconProps>(
    ({ children, onClick, className, style }, ref) => {
        return (
            <button
                ref={ref}
                className={`${styles.button} ${className || ''}`}
                onClick={onClick}
                style={style}
            >
                {children}
            </button>
        );
    }
);

ButtonIcon.displayName = 'ButtonIcon';

export default ButtonIcon;
