import React from 'react';
import styles from './Main.module.scss';
import { Link, useLocation } from 'wouter';
import BrandIcon from '../../ui/Icons/Brand';
import Sidebar from '../../components/Sidebar/Sidebar';

interface MainLayoutProps {
  children: React.ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {

  const [location] = useLocation();

  if (location.startsWith("/signin") || location.startsWith("/signup")) {
    return <>{children}</>;
  }

  return (
    <div className={styles.mainLayout}>
      <Sidebar />
      <main className={styles.mainContent}>
        {children}
      </main>
    </div>
  );
};

export default MainLayout;
