import React, { Suspense, useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import { Router, Switch, Route, Redirect } from 'wouter';
import MainLayout from './layouts/Main';

import { AuthProvider, useAuthContext } from './contexts/Auth.context';
import { UserProvider } from './contexts/User.context'

const SignIn = React.lazy(() => import('./pages/SignIn'));
const SignUp = React.lazy(() => import('./pages/SignUp'));

const Main = React.lazy(() => import('./pages/Main'));
const UTM = React.lazy(() => import('./pages/UTM'));

interface PrivateRouteProps {
  component: React.ComponentType<any>;
  path: string;
}

const OMFG = () => {
  const { loading, isAuth } = useAuthContext();
  if (loading) return null;

  const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, ...rest }) => {
    return <Route {...rest} component={isAuth ? Component : () => <Redirect to="/signin" />} />;
  };
  
  return (
    <Router>
      <UserProvider>
        <MainLayout>
          <Switch>
              <PrivateRoute path="/:project" component={() => <Suspense fallback={null}><Main /></Suspense>} />
              <PrivateRoute path="/:project/utm" component={() => <Suspense fallback={null}><UTM /></Suspense>} />
            <Route path="/signin" component={() => isAuth ? <Redirect to="/:project" /> : <Suspense fallback={null}><SignIn /></Suspense>} />
            <Route path="/signup" component={() => isAuth ? <Redirect to="/:project" /> : <Suspense fallback={null}><SignUp /></Suspense>} />
          </Switch>
        </MainLayout>
      </UserProvider>
    </Router>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  // <React.StrictMode>
    <AuthProvider>
      <OMFG />
    </AuthProvider>
  // </React.StrictMode>
);
