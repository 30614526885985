// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Main_mainLayout__PhuvC {
  display: flex;
  height: 100vh;
  box-sizing: border-box;
}
.Main_mainLayout__PhuvC > main {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.02);
  border-radius: 16px;
  padding: 24px;
  margin: 12px 12px 12px 0;
}`, "",{"version":3,"sources":["webpack://./src/layouts/Main/Main.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,aAAA;EACA,sBAAA;AACJ;AACI;EACI,WAAA;EACA,2CAAA;EACA,mBAAA;EACA,aAAA;EACA,wBAAA;AACR","sourcesContent":[".mainLayout {\n    display: flex;\n    height: 100vh;\n    box-sizing: border-box;\n\n    > main {\n        width: 100%;\n        background-color: rgba($color: #FFF, $alpha: 0.02);\n        border-radius: 16px;\n        padding: 24px;\n        margin: 12px 12px 12px 0;\n\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainLayout": `Main_mainLayout__PhuvC`
};
export default ___CSS_LOADER_EXPORT___;
