// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ButtonIcon_button__8Xfzb {
  background: transparent;
  border: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ButtonIcon_button__8Xfzb > * {
  transition: transform 0.2s;
}
.ButtonIcon_button__8Xfzb:active > * {
  transform: scale(0.8);
}`, "",{"version":3,"sources":["webpack://./src/ui/ButtonIcon/ButtonIcon.module.scss"],"names":[],"mappings":"AAAA;EACI,uBAAA;EACA,SAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACJ;AACI;EACI,0BAAA;AACR;AAEI;EACI,qBAAA;AAAR","sourcesContent":[".button {\r\n    background: transparent;\r\n    border: 0;\r\n    cursor: pointer;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n\r\n    > * {\r\n        transition: transform .2s;\r\n    }\r\n\r\n    &:active > * {\r\n        transform: scale(0.8);\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `ButtonIcon_button__8Xfzb`
};
export default ___CSS_LOADER_EXPORT___;
