// contexts/User.context.tsx

import React, {
    createContext,
    useContext,
    useEffect,
    useState,
    ReactNode,
    Dispatch,
    SetStateAction,
  } from 'react';
  import $api from '../api/xhr';
  import { IProject } from '../models';
  
  interface UserContextType {
    projects: IProject[];
    activeProject: IProject | null;
    setActiveProject: Dispatch<SetStateAction<IProject | null>>;
  }
  
  const UserContext = createContext<UserContextType | undefined>(undefined);
  
  interface UserProviderProps {
    children: ReactNode;
  }
  
  export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
    const [projects, setProjects] = useState<IProject[]>([]);
    const [activeProject, setActiveProject] = useState<IProject | null>(null);
  
    useEffect(() => {
      const savedActiveProject = localStorage.getItem('activeProject');
      if (savedActiveProject) {
        try {
          const parsedProject = JSON.parse(savedActiveProject) as IProject;
          setActiveProject(parsedProject);
        } catch (e) {
          console.error('Failed to parse active project from localStorage:', e);
        }
      }
    }, []);
  
    useEffect(() => {
      const fetchProjects = async () => {
        try {
          const response = await $api.get<IProject[]>('/projects');
          if (response) {
            setProjects(response);
            const savedActiveProject = localStorage.getItem('activeProject');
            if (savedActiveProject) {
              const parsedProject = JSON.parse(savedActiveProject) as IProject;
              const foundProject = response.find(
                (project) => project.domain === parsedProject.domain
              );
              if (foundProject) {
                setActiveProject(foundProject);
              } else {
                setActiveProject(response[0] || null);
              }
            } else {
              setActiveProject(response[0] || null);
            }
          }
        } catch (e) {
          alert('Failed to fetch projects');
        }
      };
      fetchProjects();
    }, []);
  
    useEffect(() => {
      if (activeProject) {
        localStorage.setItem('activeProject', JSON.stringify(activeProject));
      } else {
        localStorage.removeItem('activeProject');
      }
    }, [activeProject]);
  
    return (
      <UserContext.Provider value={{ projects, activeProject, setActiveProject }}>
        {children}
      </UserContext.Provider>
    );
  };
  
  export const useUser = (): UserContextType => {
    const context = useContext(UserContext);
    if (!context) {
      throw new Error('useUser must be used within a UserProvider');
    }
    return context;
  };
  