import React from 'react';
import ButtonIcon from '../../../ui/ButtonIcon';
import styles from './ProjectCards.module.scss';
import useAverageColorBySrc from '../../../utils/getAverageColorBySrc';
import { Link } from 'wouter';
import { IProject } from '../../../models';

const ProjectCard: React.FC<{

  project: IProject;
  isActive: boolean;
  setActiveProject: (project: IProject) => void;

}> = ({ project, isActive, setActiveProject }) => {
  const faviconSrc = `https://webstatify.com/proxy/https://${project.domain}/favicon.ico`;
  // const averageColor = useAverageColorBySrc(faviconSrc);

  // console.log('color', averageColor);

  return (
    <ButtonIcon onClick={() => setActiveProject(project)}>
      <Link
        className={`${styles.card} ${isActive ? styles.active : ''}`}
        to={`/${project.domain}`}
      >
        <img 
          src={faviconSrc} 
          className={styles.cardIcon} 
          // style={
          //   isActive && averageColor
          //     ? { boxShadow: `0 0 10px ${averageColor}` }
          //     : {}
          // }
        />
      </Link>
    </ButtonIcon>
  );
};

const ProjectCards: React.FC< {

  projects: IProject[];
  activeProject: IProject | null;
  setActiveProject: (project: IProject) => void;
  sidebarCollapsed: boolean;

}> = ({
  projects,
  activeProject,
  setActiveProject,
  sidebarCollapsed,
}) => {
  const calculateTransform = () => {
    if (!sidebarCollapsed) return 'translateX(0)';
    const activeIndex = projects.findIndex(
      (project) => project.domain === activeProject?.domain
    );
    if (activeIndex === -1) return 'translateX(0)';
    const cardWidth = 44 + 12; // 44px card width + 12px margin
    const offset = -(activeIndex * cardWidth);
    return `translateX(${offset}px)`;
  };

  
  return (
    <div className={styles.projectCards} style={{ transform: calculateTransform() }}>
      {projects.map((project, index) => (
          <ProjectCard
            key={index}
            project={project}
            isActive={project.domain === activeProject?.domain}
            setActiveProject={setActiveProject}
          />
      ))}
      <ButtonIcon>
        <div className={styles.card}>
          <span className={styles.plusIcon}>
						<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line x1="8" y1="1" x2="8" y2="15" stroke="#484849" strokeWidth="2" strokeLinecap="round"/>
              <line x1="1" y1="8" x2="15" y2="8" stroke="#484849" strokeWidth="2" strokeLinecap="round"/>
            </svg>
          </span>
        </div>
      </ButtonIcon>
    </div>
  );
};

export default ProjectCards;
