// contexts/Auth.context.tsx

import React, { createContext, useContext, useState, ReactNode, useEffect, useMemo } from 'react';
import $api from '../api/xhr';

interface AuthContextProps {
    user: any;
    isAuth: boolean;
    logout: () => void;
    setAuth: (user: any) => void;
    loading: boolean;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const useAuthContext = (): AuthContextProps => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuthContext must be used within an AuthProvider');
    }
    return context;
};

const initialAuthState = {
    user: null,
    isAuth: false,
};

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [authState, setAuthState] = useState(initialAuthState);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            // setAuthState((prevState) => ({
            //     ...prevState,
            //     isAuth: true,
            // }));
            checkAuth();
        } else {
            setLoading(false);
        }
    }, []);

    const checkAuth = async () => {
        try {
            const response = await $api.get<any>('/user');
            setAuthState((prevState) => ({
                ...prevState,
                user: response.user, isAuth: true
            }));
        } catch (error: any) {
            if (error.status === 401) {
                localStorage.removeItem("token");
            }
        } finally {
            setLoading(false);
        }
    };

    const logout = async () => {
        setAuthState({ user: null, isAuth: false });
        try { await $api.delete<any>('/logout'); } catch (e) { }
        localStorage.removeItem("token");
    };

    const setAuth = (user: any) => {
        setAuthState({ user, isAuth: true });
    };

    const value = useMemo(() => ({
        ...authState,
        logout,
        setAuth,
        loading,
    }), [authState, loading]);

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};
