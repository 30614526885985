// Dmitriy NanoAPI Lib v1.0

type HttpMethod = 'GET' | 'POST' | 'DELETE' | 'PUT';

interface RequestOptions {
    headers?: Record<string, string>;
    body?: any;
}

const request = <T>(endpoint: string, method: HttpMethod, body?: any, options: RequestOptions = {}): Promise<T> => {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open(method, `https://api.webstatify.com${endpoint}`, true);

        const token = localStorage.getItem('token');
        if (token) {
            xhr.setRequestHeader('Authorization', token);
        }

        if (options.headers) {
            Object.keys(options.headers).forEach(key => {
                xhr.setRequestHeader(key, options.headers![key]);
            });
        }

        xhr.withCredentials = true;

        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
                if (xhr.status >= 200 && xhr.status < 300) {
                    try {
                        const response = JSON.parse(xhr.responseText);
                        resolve(response);
                    } catch (error) {
                        resolve(undefined as unknown as T);
                    }
                } else {
                    let message;
                    try { message = JSON.parse(xhr.responseText).message; } catch (e) {}

                    reject({
                        status: xhr.status,
                        message: message,
                    });            
                }
            }
        };

        xhr.onerror = () => reject(new Error('Network error'));

        if (body) {
            if (body instanceof FormData) {
                xhr.send(body);
            } else {
                xhr.setRequestHeader('Content-Type', 'application/json');
                xhr.send(JSON.stringify(body));
            }
        } else {
            xhr.send();
        }
    });
};

const $api = {
    get: <T>(endpoint: string, options?: RequestOptions): Promise<T> => request<T>(endpoint, 'GET', undefined, options),
    post: <T>(endpoint: string, body?: any, options?: RequestOptions): Promise<T> => request<T>(endpoint, 'POST', body, options),
    put: <T>(endpoint: string, body?: any, options?: RequestOptions): Promise<T> => request<T>(endpoint, 'PUT', body, options),
    delete: <T>(endpoint: string, options?: RequestOptions): Promise<T> => request<T>(endpoint, 'DELETE', undefined, options),
};

export default $api;