// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProjectCards_projectCards__pTNJW {
  display: flex;
  justify-content: flex-start;
  gap: 12px;
  margin: 12px 0 24px 0;
  padding: 0 12px;
  transition: transform 0.3s;
}
.ProjectCards_projectCards__pTNJW .ProjectCards_card__\\+uZC7 {
  width: 44px;
  height: 44px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 13px;
  flex-shrink: 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  transition: background 0.3s, box-shadow 0.3s, opacity 0.3s, transform 0.3s;
  opacity: 0.4;
}
.ProjectCards_projectCards__pTNJW .ProjectCards_card__\\+uZC7.ProjectCards_active__jaF8K {
  box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.0509803922);
  opacity: 1;
}
.ProjectCards_projectCards__pTNJW .ProjectCards_card__\\+uZC7 .ProjectCards_plusIcon__41Ci7 {
  font-size: 24px;
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
  justify-content: center;
}
.ProjectCards_projectCards__pTNJW .ProjectCards_cardIcon__y4M86 {
  position: absolute;
  width: 18px;
  height: 18px;
  z-index: 2;
}
.ProjectCards_projectCards__pTNJW .ProjectCards_cardIconBlur__cYuSp {
  position: absolute;
  width: 24px;
  height: 24px;
  z-index: 1;
  opacity: 0;
  transition: filter 0.3s, opacity 0.3s;
}
.ProjectCards_projectCards__pTNJW .ProjectCards_cardIconBlur__cYuSp.ProjectCards_activeBlur__H1Boc {
  filter: blur(15px);
  opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/components/Sidebar/projectCards/ProjectCards.module.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,2BAAA;EACA,SAAA;EACA,qBAAA;EACA,eAAA;EACA,0BAAA;AACD;AACC;EACE,WAAA;EACA,YAAA;EACA,qCAAA;EACA,mBAAA;EACA,cAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;EACA,eAAA;EACA,0EAAA;EACA,YAAA;AACH;AAEG;EACE,6DAAA;EACA,UAAA;AAAL;AAGG;EACE,eAAA;EACA,+BAAA;EACA,kBAAA;EACA,uBAAA;AADL;AAKC;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,UAAA;AAHH;AAMC;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,UAAA;EACA,UAAA;EACA,qCAAA;AAJH;AAMG;EACE,kBAAA;EACA,UAAA;AAJL","sourcesContent":[".projectCards {\n\tdisplay: flex;\n\tjustify-content: flex-start;\n\tgap: 12px;\n\tmargin: 12px 0 24px 0;\n\tpadding: 0 12px;\n\ttransition: transform .3s;\n\n\t.card {\n\t\t\twidth: 44px;\n\t\t\theight: 44px;\n\t\t\tbackground: rgba(255, 255, 255, 0.05);\n\t\t\tborder-radius: 13px;\n\t\t\tflex-shrink: 0;\n\t\t\tposition: relative;\n\t\t\tdisplay: flex;\n\t\t\talign-items: center;\n\t\t\tjustify-content: center;\n\t\t\toverflow: hidden;\n\t\t\tcursor: pointer;\n\t\t\ttransition: background 0.3s, box-shadow 0.3s, opacity 0.3s, transform 0.3s;\n\t\t\topacity: 0.4;\n\t\t\t\n\t\t\t// active border icon\n\t\t\t&.active {\n\t\t\t\t\tbox-shadow: inset 0 0 0 2px #ffffff0d;\n\t\t\t\t\topacity: 1;\n\t\t\t}\n\n\t\t\t.plusIcon {\n\t\t\t\t\tfont-size: 24px;\n\t\t\t\t\tcolor: rgba(255, 255, 255, 0.5);\n\t\t\t\t\ttext-align: center;\n\t\t\t\t\tjustify-content: center;\n\t\t\t}\n\t}\n\n\t.cardIcon {\n\t\t\tposition: absolute;\n\t\t\twidth: 18px;\n\t\t\theight: 18px;\n\t\t\tz-index: 2;\n\t}\n\n\t.cardIconBlur {\n\t\t\tposition: absolute;\n\t\t\twidth: 24px;\n\t\t\theight: 24px;\n\t\t\tz-index: 1;\n\t\t\topacity: 0;\n\t\t\ttransition: filter 0.3s, opacity 0.3s;\n\n\t\t\t&.activeBlur {\n\t\t\t\t\tfilter: blur(15px);\n\t\t\t\t\topacity: 1;\n\t\t\t}\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"projectCards": `ProjectCards_projectCards__pTNJW`,
	"card": `ProjectCards_card__+uZC7`,
	"active": `ProjectCards_active__jaF8K`,
	"plusIcon": `ProjectCards_plusIcon__41Ci7`,
	"cardIcon": `ProjectCards_cardIcon__y4M86`,
	"cardIconBlur": `ProjectCards_cardIconBlur__cYuSp`,
	"activeBlur": `ProjectCards_activeBlur__H1Boc`
};
export default ___CSS_LOADER_EXPORT___;
