// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0D0D0E;
}

body, input, button {
  font-family: -apple-system, BlinkMacSystemFont, Inter, sans-serif;
}

button {
  padding: 0;
}

a {
  color: #fff;
  text-decoration: none;
}

ul {
  margin: 0;
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/index.scss"],"names":[],"mappings":"AAEA;EACE,SAAA;EACA,mCAAA;EACA,kCAAA;EAGA,yBAAA;AAHF;;AAMA;EACE,iEAAA;AAHF;;AAMA;EACE,UAAA;AAHF;;AAMA;EACE,WAAA;EACA,qBAAA;AAHF;;AAMA;EACE,SAAA;EACA,UAAA;AAHF","sourcesContent":["\n\nbody {\n  margin: 0;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  // background-color: #0D0D0E;\n  // background-color: #0d0d10;\n  background-color: #0D0D0E;\n}\n\nbody, input, button {\n  font-family: -apple-system, BlinkMacSystemFont, Inter, sans-serif;\n}\n\nbutton {\n  padding: 0;\n}\n\na {\n  color: #fff;\n  text-decoration: none;\n}\n\nul {\n  margin: 0;\n  padding: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
